import { is_empty } from "utils/validations";
import {
  EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS,
  EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS,
} from "./../../../schedule/redux/actions";
import dataProvider from "./../../../../../data/dataProvider";
import api from "./../../../../../data/APIs";
import constants from "constants/constants";
import { labels } from "../constants";
import { apiMethods } from "data/api.constants";
import { isRequestSuccessful } from "utils/Utils";
import { notification_color_keys } from "utils/hooks";
import { logError } from "utils/error";

export const prepareListingsData = (fetchedListings, selected_uuid) => {
  if (
    is_empty(fetchedListings?.past?.data) &&
    is_empty(fetchedListings?.draft?.data) &&
    is_empty(fetchedListings?.live?.data) &&
    selected_uuid
  )
    return [];
  const prepareData = [
    ...(fetchedListings?.live?.data || {}),
    ...(fetchedListings?.draft?.data || {}),
    ...(fetchedListings?.past?.data || {}),
  ];
  prepareData.sort(function (a) {
    if (a.uuid === selected_uuid) return -1;
    return 0;
  });

  return prepareData.map((listing) => ({
    ...listing,
    id: listing.uuid,
    label: listing.title,
    value: listing.uuid,
    type: listing.type,
    start_date: listing.offering_start_date,
    end_date: listing.offering_last_date,
    course_end_magnitude: listing.course_end_magnitude,
    course_end_choice: listing.course_end_choice,
  }));
};

export const dispatchListingData = (fetchedListings, dispatch) => {
  if (
    is_empty(fetchedListings.live.data) &&
    is_empty(fetchedListings.draft.data) &&
    is_empty(fetchedListings.past.data)
  ) {
    dispatch({
      type: EXLY_SCHEDULELIST__REQUEST_LIVE_OFFERINGS,
      payload: { fetchAll: true },
    });
    dispatch({
      type: EXLY_SCHEDULELIST__REQUEST_PAST_OFFERINGS,
      payload: { fetchAll: true },
    });
    dispatch({
      type: EXLY_SCHEDULELIST__REQUEST_DRAFT_OFFERINGS,
      payload: { fetchAll: true },
    });
  }
};

export const handleSave = async (props) => {
  const { selectedRecordData, notify, refetch, isModal, setShowUpload } = props;
  try {
    const payload = validateSaveFaq(props);
    const status = await dataProvider.custom_request(
      !is_empty(selectedRecordData?.uuid) ? api.faq_update : api.faq_insert,
      apiMethods.POST,
      payload
    );
    notify("FAQ Added Successfully", notification_color_keys.success);
    if (isRequestSuccessful(status.status)) {
      refetch();
      if (isModal && setShowUpload) {
        setShowUpload(false);
      }
    }
  } catch (err) {
    notify(
      typeof err === "string" ? err : constants.error_message,
      notification_color_keys.error
    );
  }
};

export const handleDelete = async (props) => {
  const {
    selectedRecordData,
    setOpenDelete,
    refetch,
    pushNotification,
    setOpenAccordion,
  } = props;
  try {
    if (is_empty(selectedRecordData.uuid)) return;
    const status = await dataProvider.custom_request(api.faq_delete, "POST", {
      uuid: selectedRecordData.uuid,
    });
    pushNotification(status.message, {
      variant: "outlined",
      color: "primary",
    });
    if (status.status === 200) {
      setOpenDelete(false);
      setOpenAccordion("");
      refetch();
    }
  } catch (error) {
    logError({
      error,
      when: "FAQ handleDelete",
      occuredAt: "src/ui/pages/manageWebpage/FAQ/components/helpers.js",
      severity: "blocker",
    });
    pushNotification(constants.error_message, {
      variant: "outlined",
      color: "coral_red",
    });
  }
};

export const isNotFetching = (fetchedListings) =>
  !fetchedListings.live.isLoading &&
  !fetchedListings.past.isLoading &&
  !fetchedListings.draft.isLoading;

export const validateSaveFaq = (props) => {
  const {
    question,
    offering,
    getHtmlFromEditorState,
    editorState,
    selectedRecordData,
    emptyHtmlWithListStyle,
    emptyHtml,
  } = props;

  const answer = getHtmlFromEditorState(editorState);
  const isEditorEmpty =
    answer === emptyHtmlWithListStyle || answer === emptyHtml;

  if (is_empty(offering) || is_empty(question) || isEditorEmpty) {
    let errorMsg = "";
    if (is_empty(question)) errorMsg = "Question Field cannot be empty";
    else if (isEditorEmpty) errorMsg = "Answer Field cannot be empty";

    throw errorMsg;
  }

  let payload = {
    question: question,
    answer: answer,
  };
  if (!is_empty(offering)) {
    Object.assign(payload, {
      listing_uuid: labels.ALL_OFFERINGS === offering ? null : offering,
    });
  }
  if (!is_empty(selectedRecordData?.uuid)) {
    Object.assign(payload, { uuid: selectedRecordData?.uuid });
  }

  return payload;
};
