import React, { useEffect, useState } from "react";
import { is_empty } from "../../../../../../utils/validations";
import {
  RichTextEditor,
  RichTextEditorUtils,
  Input,
  Button,
} from "@my-scoot/component-library-legacy";
import IconButton from "@material-ui/core/IconButton";
import { useNotifications } from "utils/hooks";
import { useSelector, useDispatch } from "react-redux";
import { Delete } from "@material-ui/icons";

import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { useStyles } from "./styles";
import {
  dispatchListingData,
  handleSave,
  handleDelete,
} from "./../helpers";
import ExlyDeleteModal from "common/Components/ExlyDeleteModal/ExlyDeleteModal";
import { labels } from "../../constants";
import moment from "moment";
import globalConstants from "constants/constants";
import { uploadToS3 } from "features/Common/modules/S3/utils/S3.utils";
import { logError } from "utils/error";
import { useParams } from "react-router-dom";

const FaqEditor = (props) => {
  const {
    selectedRecordData = {},
    refetch = () => {},
    showDelete = false,
    isModal = false,
    setShowUpload = false,
    setOpenAccordion,
  } = props;
  const dispatch = useDispatch();
  const { notify, pushNotification } = useNotifications();

  const isDesktop = useDesktopMediaQuery();
  const css = useStyles({ isDesktop, isModal });

  const {
    getEditorStateFromHtml,
    getHtmlFromEditorState,
    emptyEditorState,
    emptyHtmlWithListStyle,
    emptyHtml,
  } = RichTextEditorUtils;

  const { listing_uuid } = useParams();
  const fetchedListings = useSelector((state) => state.schedule);

  const [editorState, setEditorState] = useState(emptyEditorState);
  const [offering, setOffering] = useState(null);
  const [question, setQuestion] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [canSave, setCanSave] = useState(isModal);

  useEffect(() => {
    (async () => {
      setEditorState(getEditorStateFromHtml(selectedRecordData?.answer));
      setQuestion(selectedRecordData?.question);
      setOffering(
        is_empty(selectedRecordData?.listing__uuid)
          ? labels.ALL_OFFERINGS
          : selectedRecordData?.listing__uuid
      );
    })();

  }, []);

  useEffect(() => {
    if (listing_uuid) setOffering(listing_uuid);
  }, [listing_uuid]);

  useEffect(() => {
    dispatchListingData(fetchedListings, dispatch);
  }, []);

  const handleDeleteModal = () => setOpenDelete(!openDelete);

  let actionButtons = [];

  if (showDelete)
    actionButtons.push(
      <IconButton onClick={handleDeleteModal} key="delete">
        <Delete />
      </IconButton>
    );

  actionButtons.push(
    <React.Fragment key="save">
      <Button
        color="primary"
        onClick={() =>
          handleSave({
            question,
            offering,
            getHtmlFromEditorState,
            editorState,
            selectedRecordData,
            notify,
            refetch,
            isModal,
            setShowUpload,
            emptyHtmlWithListStyle,
            emptyHtml,
          })
        }
        fullWidth={isModal && !isDesktop}
        disabled={!canSave}
      >
        {isModal ? labels.ADD_FAQS : labels.SAVE_FAQ}
      </Button>
    </React.Fragment>
  );

  const notModalEnableSave = () => !isModal && setCanSave(true);

  const onChangeQuestion = (e) => {
    notModalEnableSave();
    setQuestion(e.target.value);
  };

  const onChangeEditor = (val) => {
    notModalEnableSave();
    setEditorState(val);
  };

  const handleFileUpload = async (file) => {
    try {
      const fileName = file.name;
      let key = `faq-attachments/${moment().unix()}/${fileName}`;
      const fileData = await uploadToS3({
        file,
        key,
        bucket: globalConstants.AWS_ATTACHMENT_BUCKET,
      });
      return fileData.Location;
    } catch (err) {
      logError({
        error: err,
        when: "handleFileUpload",
        occuredAt: "FaqEditor.js",
      });
    }
  };

  return (
    <div className={css.faqEditorBody}>
      <div>
        <Input
          label="Question*"
          size="small"
          fullWidth={true}
          value={question}
          onChange={onChangeQuestion}
        />
        <div className={css.faqResizable}>
          <label className={css.label}>Answer*</label>
          <RichTextEditor
            modalTitle="Description"
            editorState={editorState}
            onEditorStateChange={onChangeEditor}
            placeholder="Write Something here..."
            toolbarPosition="top"
            minHeight={isDesktop ? "200px" : "240px"}
            imageUploadCallback={handleFileUpload}
          />
        </div>
        <div className={css.actionButtons}>{actionButtons}</div>
      </div>
      <ExlyDeleteModal
        title="Delete FAQ"
        deleteBtnText="Confirm"
        description="Are you sure you want to delete the FAQ?"
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onDelete={() =>
          handleDelete({
            selectedRecordData,
            setOpenDelete,
            refetch,
            pushNotification,
            setOpenAccordion,
          })
        }
        onSecondaryBtnClick={() => setOpenDelete(false)}
        padded={false}
      />
    </div>
  );
};

export default FaqEditor;
