import React, { useState, useEffect } from "react";
import { useRefresh } from "react-admin";
import ExlyFilter from "common/Components/ExlyTable/ExlyTableFilters";
import FaqEditor from "../faqList/FaqEditor";
import FaqListing from "../faqList/FaqListing";
import Fallback from "../fallBack/FallBack";
import NoResults from "../fallBack/NoResults";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import { is_empty } from "../../../../../../utils/validations";
import { constants } from "../../constants";
import AccordionPlaceHolder from "../accordionPlaceHolder/AccordionPlaceHolder";
import { filterConfig, tableChipsFormatter } from "../../constants";
import ExlyModal from "common/Components/ExlyModal";
import { useListController } from "react-admin";
import { Pagination } from "common/Components/Pagination";
import useStyles from "./styles";
import { getParameterByName } from "utils/Utils";

const PageBody = (props) => {
  const { showUpload, setShowUpload, openAccordion, setOpenAccordion, offeringLevel } = props;
  const refetch = useRefresh();

  const isDesktop = useDesktopMediaQuery();
  const css = useStyles(isDesktop);

  const { loaded, data, filterValues, page, setPage, total, setFilters } =
    useListController(props);
  const [masterList, setMasterList] = useState(data);
  const [selectedRecordData, setSelectedRecordData] = useState(null);

  React.useEffect(() => {
    let manageOfferingFilter = getParameterByName("manageOffering");

    if (!is_empty(manageOfferingFilter)) {
      setFilters({ listing__uuid: manageOfferingFilter });
      setSelectedRecordData({ listing__uuid: manageOfferingFilter });
    }
  }, []);

  // Setting FAQ data from React admin
  useEffect(() => {
    setMasterList(data);
  }, [data]);

  // No Data
  const noData = is_empty(data) && loaded && is_empty(filterValues);

  // No Results
  const noSearchResults = !is_empty(filterValues) && is_empty(data);

  // Filter Config
  const _filters = filterConfig();

  return (
    <>
      {(!noData && !offeringLevel) &&  (
        <ExlyFilter
          onClear={() => {
            setFilters({});
            setSelectedRecordData(null);
          }}
          filters={_filters}
          tabFilterConfig={[]}
          setFilters={setFilters}
          filterValues={filterValues}
          tableChipsFormatter={tableChipsFormatter}
          noExportButton={true}
          isDesktop={isDesktop}
          hasTabs={false}
          hideFilters={false}
        />
      )}
      {noData && (
        <Fallback {...constants.noData} handleBtn={() => setShowUpload(true)} />
      )}
      {!loaded && <AccordionPlaceHolder />}
      {!noData && (
        <FaqListing
          list={masterList}
          openAccordion={openAccordion}
          setOpenAccordion={setOpenAccordion}
          hideGroupHeading={offeringLevel}
        />
      )}
      {noSearchResults && <NoResults />}
      <Pagination
        page={page}
        perPage="50"
        onPageChange={setPage}
        total={total}
      />

      <ExlyModal
        title="Add FAQs"
        header="Add FAQs"
        primaryBtnText="Confirm"
        secondaryBtnText="Cancel"
        open={showUpload}
        onClose={() => setShowUpload(false)}
        modal_props={{
          customFooter: <></>,
          modalPaperClassName: css.modalPaperClassName,
        }}
        mobile_modal_props={{
          customFooter: <></>,
          paperClassName: "",
        }}
        padded={false}
      >
        {showUpload && (
          <FaqEditor
            refetch={(value) => {
              refetch(value);
            }}
            selectedRecordData={selectedRecordData}
            isModal={true}
            setShowUpload={setShowUpload}
          />
        )}
      </ExlyModal>
    </>
  );
};

export default PageBody;
