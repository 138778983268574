import * as React from "react";

import Info from "@material-ui/icons/InfoRounded";
import constants from "../../../constants/constants";
import { is_empty, is_text_without_space } from "../../../utils/validations";
import styles from "./coupon.module.css";

import {
  Avatar,
  Checkbox,
  Chip,
  creatorToolTheme,
  ThemeProvider,
  Tooltip,
} from "@my-scoot/component-library-legacy";
import useStyles from "./discounts.styles";

import { ExlyDropdown, ExlyInput, ExlyOfferingSelect } from "common/form";
import ExlyAutoComplete from "common/form/ExlyAutoComplete";
import ExlyCheckbox from "common/form/ExlyCheckbox";
import { Field, Form } from "react-final-form";
import {
  getUserCurrency,
  isInternationalTimezone,
} from "../../../utils/AuthUtil";
import useCreateDiscounts from "./useCreateDiscounts";
import { CircularProgress } from "@material-ui/core";
import ExlyRadioGroup from "common/form/ExlyRadioGroup/Index";
import { checkIsAlphaNumeric } from "features/Common/modules/Form/utils/Form.validations.utils";

{
  /* 
 format group adience key to be sent, as backend expects value as 
 ["all_leads", "all_customers"], but currently it is 
 ["All Leads(161)", "All Customers(127)"]
 */
}
const formatAudienceKey = (str) => {
  return str
    .toLowerCase()
    .replace(/\s/g, "_") // to replace space with underscore
    .replace(/[^a-z_]/g, ""); // remove any character other than a-z and "_"
};

const CreateDiscounts = React.forwardRef(
  ({ onSubmit, component: WrapperComponent, ...restProps }, ref) => {
    const classes = useStyles();

    const {
      form_fields,
      couponApplicable,
      CustomerEligibility,
      offeringsOptions,
      getSpecificGroupsOrCustomersOptions,
      addDiscountCoupon,
      handleDeleteOption,
      loading,
      customerEligibilityRadioOptions,
    } = useCreateDiscounts(restProps);

    const formRef = React.useRef(null);

    const { listingMultiSelect } = restProps;

    React.useImperativeHandle(ref, () => ({
      triggerSave() {
        if (!formRef.current) return;

        const { submit } = formRef.current;

        submit();
      },
      form: formRef.current,
    }));

    const validateDiscountCode = (discountCode) => {
      if (is_empty(discountCode)) return "Discount code can't be empty!";

      if (is_text_without_space(discountCode))
        return "You can't use blank spaces in the discount code.";

      if (!checkIsAlphaNumeric(discountCode))
        return "Only Alphanumeric characters are allowed";

      if (discountCode.length > 10)
        return "Discount code length can't be more than 10 characters";

      return null;
    };

    const validateDiscountValue = ({ discountValue, type }) => {
      if (is_empty(discountValue)) return "Discount value can't be empty!";

      if (
        discountValue < 1 ||
        (discountValue > 100 && type == form_fields.percentage_type)
      )
        return "Discount Percentage should be between 1-100";

      if (isNaN(discountValue)) return "Value cannot be non numeric";

      return null;
    };

    const validateUsageLimit = ({ usageLimit }) => {
      if (is_empty(usageLimit)) return "Usage limit can't be empty!";
      if (parseInt(usageLimit) < 1)
        return "Usage limit should be greater than 0.";
      return null;
    };

    const validateSelectOfferings = (offerings) =>
      is_empty(offerings) ? "Select offerings" : null;

    const LoadingState = () => (
      <div className={classes.loading}>
        <CircularProgress size={24} color="primary" />
        <div>Getting things ready...</div>
      </div>
    );

    const Fields = ({ children, ...restProps }) => {
      return WrapperComponent ? (
        <WrapperComponent {...restProps}>{children}</WrapperComponent>
      ) : (
        children
      );
    };

    if (loading && !WrapperComponent) return <LoadingState />;

    const validateValues = (values) => {
      const errors = {};
      if (values.type === form_fields.percentage_type) {
        if (parseInt(values.usage_limit) > 1 && parseInt(values.value) > 50) {
          errors.value = "Max 50% discount value for usage limit more than 1";
        }
      }
      return errors;
    };

    return (
      <ThemeProvider theme={creatorToolTheme}>
        <Form
          initialValues={{
            type: 1,
            code: "",
            currency: getUserCurrency(),
            applicability: couponApplicable.everyone,
            customerEligibilityType: CustomerEligibility.everyone,
            listing_uuids: listingMultiSelect ? [] : "",
            audience: {
              extras: [],
              groups: [],
            },
            listInputFocused: false,
            options: getSpecificGroupsOrCustomersOptions(""),
            filterQuery: "",
            loading: false,
          }}
          validate={validateValues}
          onSubmit={async (values) => {
            const response = await addDiscountCoupon(values);
            onSubmit(response);
          }}
        >
          {({ handleSubmit, values, form, ...restFormProps }) => {
            formRef.current = form;
            const extrasCount = values.audience?.extras?.length;

            React.useEffect(() => {
              if (extrasCount) {
                form.change("is_promotional_coupon", false);
              }
            }, [extrasCount, form]);

            return (
              <div className={styles.createDiscount}>
                <Fields {...{ ...restFormProps, loading, LoadingState }}>
                  <form className={classes.form} onSubmit={handleSubmit}>
                    <Field
                      name="type"
                      component={ExlyDropdown}
                      size="small"
                      fullWidth
                      dropdownLabel={{
                        label: "Type",
                        size: "small",
                        weight: "semi-bold",
                      }}
                      options={[
                        { label: "Amount", value: 2 },
                        { label: "Percentage", value: 1 },
                      ]}
                      onChange={() => {
                        form.change("currency_type", getUserCurrency());
                      }}
                    />

                    <Field
                      name="code"
                      component={ExlyInput}
                      labelClassName={classes.inputLabel}
                      fullWidth
                      label="Discount Code"
                      placeholder="Enter discount code e.g EXLY50"
                      helperText="Customers will enter this discount code at checkout."
                      size="small"
                      format={(value) => value?.toUpperCase()}
                      parse={(value) => value?.toUpperCase()}
                      validate={validateDiscountCode}
                    />

                    {!isInternationalTimezone() && values.type == 2 && (
                      <Field
                        component={ExlyDropdown}
                        name="currency"
                        fullWidth
                        size="small"
                        dropdownLabel={{
                          label: "Currency",
                          size: "small",
                          weight: "semi-bold",
                        }}
                        options={[
                          { label: "INR", value: constants.inr },
                          { label: "USD", value: constants.usd },
                        ]}
                      />
                    )}

                    <Field
                      name="value"
                      component={ExlyInput}
                      labelClassName={classes.inputLabel}
                      fullWidth
                      type="number"
                      size="small"
                      label="Discount Value"
                      placeholder="Enter discount value"
                      validate={(value) =>
                        validateDiscountValue({
                          discountValue: value,
                          type: values.type,
                        })
                      }
                    />

                    <Field
                      name="usage_limit"
                      component={ExlyInput}
                      labelClassName={classes.inputLabel}
                      fullWidth
                      type="number"
                      label="Usage Limit"
                      placeholder="Usage Limit eg. 10"
                      size="small"
                      validate={(value) =>
                        validateUsageLimit({
                          usageLimit: value,
                        })
                      }
                    />

                    <Field
                      name="applicability"
                      component={ExlyDropdown}
                      size="small"
                      fullWidth
                      dropdownLabel={{
                        label: "Applicability of this discount code",
                        size: "small",
                        weight: "semi-bold",
                      }}
                      options={[
                        { label: "Everyone", value: couponApplicable.everyone },
                        {
                          label: "Specific Offerings",
                          value: couponApplicable.specificListings,
                        },
                        {
                          label: "Loyal Customers",
                          value: couponApplicable.loyaltyUsers,
                        },
                      ]}
                      onChange={(value) => {
                        if (value == couponApplicable.loyaltyUsers) {
                          form.change("audience", {
                            ...values.audience,
                            groups: ["all"],
                          });
                        } else {
                          form.change("audience", {
                            ...values.audience,
                            extras: [],
                          });
                        }
                      }}
                    />

                    {values.applicability == couponApplicable.loyaltyUsers && (
                      <div>
                        <label className={classes.customerEligibilityHeading}>
                          Customer eligibility
                        </label>
                        <div className={classes.customerEligibilityOptions}>
                          <Field
                            name="customerEligibility"
                            component={ExlyRadioGroup}
                            isRow
                            selected={customerEligibilityRadioOptions.find(
                              (option) =>
                                option.value === values.customerEligibilityType
                            )}
                            options={customerEligibilityRadioOptions}
                            onChange={(value) => {
                              if (value === CustomerEligibility.everyone) {
                                form.change("audience", {
                                  ...values.audience,
                                  groups: ["all"],
                                  extras: [],
                                });

                                form.change(
                                  "customerEligibilityType",
                                  CustomerEligibility.everyone
                                );
                              }

                              if (
                                value === CustomerEligibility.specificGroups
                              ) {
                                form.change(
                                  "customerEligibilityType",
                                  CustomerEligibility.specificGroups
                                );

                                form.change("audience", {
                                  ...values.audience,
                                  groups: [],
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}

                    {(values.applicability ==
                      couponApplicable.specificListings ||
                      values.applicability ==
                        couponApplicable.loyaltyUsers) && (
                      <Field
                        name="listing_uuids"
                        component={ExlyOfferingSelect}
                        label="Select specific offerings"
                        multiple={listingMultiSelect}
                        showSelectAll
                        mobileModalTitle="Select Offerings"
                        placeholder="Select Offerings"
                        selectAllContent="Select All"
                        deselectAllContent="Remove All"
                        options={offeringsOptions}
                        fullWidth
                        showStatus
                        validate={validateSelectOfferings}
                      />
                    )}

                    {values.applicability == couponApplicable.loyaltyUsers &&
                      values.customerEligibilityType ==
                        CustomerEligibility.specificGroups && (
                        <Field
                          name="audience"
                          label="Select specific group or customers"
                          component={ExlyAutoComplete}
                          multiple
                          format={(value) => {
                            return (
                              value && [
                                ...value.groups.map((group) => ({
                                  key: group,
                                  groupBy:
                                    "Recipient Groups (Click on group to add)",
                                })),
                                ...value.extras.map((customer) => ({
                                  key: customer,
                                  groupBy: "Recipients",
                                })),
                              ]
                            );
                          }}
                          onChange={(value) => {
                            if (is_empty(value)) {
                              form.change("audience", {
                                groups: [],
                                extras: [],
                              });
                              return;
                            }

                            const newValue = value[value.length - 1];

                            if (newValue.key === "cta") return;

                            if (
                              values.audience.groups.includes(newValue.key) ||
                              values.audience.groups.includes(
                                formatAudienceKey(newValue.key)
                              ) ||
                              values.audience.extras.includes(newValue.key)
                            ) {
                              const { groupBy, key } = newValue;
                              if (
                                groupBy ===
                                "Recipient Groups (Click on group to add)"
                              ) {
                                let updatedGroup = values.audience.groups;
                                updatedGroup = updatedGroup.filter(
                                  (item) => item !== formatAudienceKey(key)
                                );
                                form.change("audience", {
                                  ...values.audience,
                                  groups: [...updatedGroup],
                                });
                              } else {
                                let updatedExtras = values.audience.extras;
                                updatedExtras = updatedExtras.filter(
                                  (item) => item !== key
                                );
                                form.change("audience", {
                                  ...values.audience,
                                  extras: [...updatedExtras],
                                });
                              }
                            } else if (
                              newValue.groupBy ===
                              "Recipient Groups (Click on group to add)"
                            ) {
                              form.change("audience", {
                                ...values.audience,
                                groups: [
                                  ...values.audience.groups,
                                  formatAudienceKey(newValue.key),
                                ],
                              });
                            } else {
                              form.change("audience", {
                                ...values.audience,
                                extras: [
                                  ...values.audience.extras,
                                  newValue.key,
                                ],
                              });
                            }
                          }}
                          disableCloseOnSelect
                          options={values.options}
                          groupBy={(option) => option.groupBy}
                          getOptionLabel={(option) => option.key}
                          inputValue={values.filterQuery}
                          inputProps={{
                            placeholder: "Add specific group or customers",
                            onChange: ({ target: { value } }) => {
                              form.change("filterQuery", value);
                            },
                          }}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                label={option.key}
                                {...getTagProps({ index })}
                                onDelete={() =>
                                  handleDeleteOption(option, form)
                                }
                              />
                            ))
                          }
                          renderOption={(option) => (
                            <div className={classes.autoCompleteItem}>
                              <Checkbox
                                size="large"
                                checked={
                                  values.audience.groups.includes(option.key) ||
                                  values.audience.groups.includes(
                                    formatAudienceKey(option.key)
                                  ) ||
                                  values.audience.extras.includes(option.key)
                                }
                              />
                              <Avatar label={option.key} />
                              <span className={classes.customerTitle}>
                                {option.key}
                              </span>
                            </div>
                          )}
                        />
                      )}

                    <Field
                      name="is_promotional_coupon"
                      component={ExlyCheckbox}
                      id="confirmPromoteCode"
                      size="large"
                      label="Promote Coupon Code"
                      helperNode={
                        <Tooltip
                          color="primary"
                          arrow
                          title="The coupon will be promoted to your users on the listing"
                        >
                          <Info className={classes.infoIcon} />
                        </Tooltip>
                      }
                      disabled={values.audience?.extras?.length}
                    />

                    {!isInternationalTimezone() && (
                      <Field
                        name="include_gst"
                        component={ExlyCheckbox}
                        id="confirmGST"
                        size="large"
                        label="Include the price in GST"
                        checked={values.include_gst}
                        onChange={(checked) =>
                          form.change("include_gst", checked)
                        }
                      />
                    )}

                    <div className={`${classes.altInfoBanner}`}>
                      <Info /> Discount codes automatically expire in 30 days.
                    </div>

                    <div className={`${classes.infoBanner} tw-mt-[5px]`}>
                      <Info /> Maximum discount can be 50% of the offering
                      price!
                    </div>
                  </form>
                </Fields>
              </div>
            );
          }}
        </Form>
      </ThemeProvider>
    );
  }
);

CreateDiscounts.displayName = "CreateDiscountCode";

export default React.memo(CreateDiscounts);
