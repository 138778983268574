import React from "react";
import { Button } from "@my-scoot/component-library-legacy";
import useStyles from "./styles";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import LearnMoreCta from "features/Common/modules/LearnMore/modules/LearnMoreCta/LearnMoreCta";
import { FAQ_LEARN_MORE_HREFS } from "../../constants";
import classnames from "classnames";
import { ArrowBack } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const BtnPrimary = ({ children, handleClick }) => {
  return (
    <Button color="primary" onClick={handleClick} fullWidth={true}>
      {children}
    </Button>
  );
};

const PageHead = (props) => {
  const { title, btnPrimary, primaryClick, className } = props;
  const isDesktop = useDesktopMediaQuery();
  const styles = useStyles(isDesktop);
  const history = useHistory();

  const bottomNav = (
    <div className={styles.bottomActions}>
      {btnPrimary && (
        <BtnPrimary handleClick={primaryClick}>{btnPrimary}</BtnPrimary>
      )}
    </div>
  );

  return (
    <>
      <div
        className={classnames(styles.container, "tw-items-center", className)}
      >
        {isDesktop && (
          <span className="tw-items-center tw-flex !tw-text-[28px] !tw-font-bold">
            <ArrowBack
              className="!tw-mr-4 !tw-cursor-pointer"
              onClick={() => history.goBack()}
            />
            {title}
          </span>
        )}
        <div>
          {isDesktop && btnPrimary && (
            <div className="d-flex">
              <LearnMoreCta href={FAQ_LEARN_MORE_HREFS.FAQ} className="mr-3" />
              <BtnPrimary handleClick={primaryClick}>{btnPrimary}</BtnPrimary>
            </div>
          )}
          {!isDesktop && bottomNav}
        </div>
      </div>
    </>
  );
};

export default PageHead;
